import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';

import { useSelector } from 'store';
import useUser from 'hooks/useUser';
import FormikCheckboxInput from 'components/forms/formik/FormikCheckboxInput/FormikCheckboxInput';
import LoaderOverlay from 'components/LoaderOverlay/LoaderOverlay';
import Tooltip from 'components/Tooltip/Tooltip';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';
import CommonTransComponents from 'i18n/CommonTransComponents';

import useInitialValues from './hooks/useInitialValues';
import useSchema from './hooks/useSchema';
import useOnSubmit from './hooks/useOnSubmit';

import './SubmitAssessmentAction.scss';

export interface SubmitAssessmentActionProps {
  isComplete: boolean;
  onSubmitComplete?: () => void;
  isSubmitVisible: boolean;
}

const SubmitAssessmentAction = ({ isComplete, onSubmitComplete, isSubmitVisible }: SubmitAssessmentActionProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);

  const initialValues = useInitialValues();
  const schema = useSchema();
  const onSubmit = useOnSubmit(setIsOpen, setIsSuccessOpen, onSubmitComplete);

  const isLoading = useSelector((store) => store.rfpResponse.updating);
  const organization = useSelector((store) => store.rfpResponse.data.organization.name);
  const programTitle = useSelector((store) => store.rfpResponse.data.project_name);
  const assessmentType = useSelector((store) => store.rfp.data.name);
  const { first_name, last_name } = useUser();

  return (
    <>
      {isSubmitVisible && (
        <Tooltip tooltip={t('pages.projectOverview.mainPanel.submitAssessmentHelp')} disabled={isComplete}>
          <Button variant="primary" disabled={true} onClick={() => setIsOpen(true)}> {/* Changed disabled={true} from disabled={isComplete} to disable button */}
            {t('pages.projectOverview.mainPanel.submitAssessment')}
          </Button>
        </Tooltip>
      )}
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.submitAssessmentModal.title')}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
          <Form className="enkrateia-submit-assessment-modal">
            <div className="elements">
              <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.organization')}</span>
              <span>{organization}</span>
              <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.programTitle')}</span>
              <span>{programTitle}</span>
              <span>{t('pages.projectOverview.mainPanel.changeAssessmentStatusModal.assessmentType')}</span>
              <span>{assessmentType}</span>
            </div>
            <FormikCheckboxInput
              name="checkbox"
              label={t('pages.projectOverview.mainPanel.submitAssessmentModal.label', {
                userFirstAndLastName: `${first_name} ${last_name}`,
                organizationName: organization,
              })}
              fullWidth
            />
            <FormikCheckboxInput
              name="iHaveReadTerms"
              label={
                <Trans
                  i18nKey="pages.projectOverview.mainPanel.submitAssessmentModal.iHaveReadTerms"
                  components={CommonTransComponents}
                >
                  I have read the terms
                </Trans>
              }
              fullWidth
            />
            <div className="submit-button-container">
              <Button variant="primary" type="submit">
                {t('pages.projectOverview.mainPanel.submitAssessmentModal.confirm')}
              </Button>
            </div>
            <LoaderOverlay open={isLoading} />
          </Form>
        </Formik>
      </Modal>
      <Modal
        variant="kliparo"
        title={t('pages.projectOverview.mainPanel.submitAssessmentModalSuccess.title')}
        isOpen={isSuccessOpen}
        onRequestClose={() => setIsSuccessOpen(false)}
        className="enkrateia-submit-assessment-modal-success"
      >
        <Trans
          i18nKey="pages.projectOverview.mainPanel.submitAssessmentModalSuccess.content"
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          components={{ ...CommonTransComponents, a: <a href={t('common.footer.links.contact.url')} /> }}
          tOptions={{
            url: t('common.footer.links.contact.url'),
            email: t('common.footer.links.contact.email'),
          }}
        />
        <Button onClick={() => setIsSuccessOpen(false)} variant="primary">
          {t('common.ok')}
        </Button>
      </Modal>
    </>
  );
};

export default SubmitAssessmentAction;
